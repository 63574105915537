import React, { useEffect, useState } from 'react';
import { graphql, useStaticQuery } from 'gatsby';
import styled from 'styled-components';
import Carousel from 'nuka-carousel';

import ListingDetailFeed from '../listings/ListingDetailFeed';

import prevIcon from '../../img/carousel_leftarrow.svg';
import nextIcon from '../../img/carousel_rightarrow.svg';

import { collectionOrder } from '../../util/collectionOrder';

const key = 'FGdHrOEzme6yufWFz7ap7hUhuAaRsl';

const StyledCarousel = styled.div`
	.slider {
		.slider-frame {
			overflow: visible !important;

			.slider-list {
				.slider-slide {
					opacity: 0.5;
					transition: transform 0.4s linear 0s, opacity 500ms !important;
					top: 50% !important;
					transform: scale(1) translateY(-50%) !important;

					&.slide-visible {
						opacity: 1;
					}

					[class*='ListingDetail'] {
						margin-left: 10%;
						margin-right: 10%;
					}
				}
			}
		}

		.control-button {
			width: 3rem;
			height: 2.5rem;
			background-size: contain;
			background-position: center;
			background-repeat: no-repeat;
			background-color: transparent;
			border: none;
			cursor: pointer;

			&:focus {
				outline: none;
			}

			@media (max-width: 599px) {
				width: 1.8rem;
				height: 1.5rem;
			}
		}
	}
`;

const FeaturedListingsCarousel = () => {
	// Fire a resize to try and fix carousel height issue
	useEffect(() => {
		setTimeout(() => {
			window.dispatchEvent(new Event('resize'));
		}, 1000);
	});

	/*
	const data = useStaticQuery(graphql`
		query FeaturedListingsQuery {
			allMarkdownRemark(
				filter: {
					frontmatter: {
						is_published: { eq: true }
						is_featured: { eq: true }
						is_sold: { eq: false }
						template_key: { eq: "listing" }
					}
				}
				sort: { fields: frontmatter___date, order: DESC }
			) {
				edges {
					node {
						fields {
							slug
						}
						frontmatter {
							title
							city
							province
							price
							beds
							baths
							excerpt
							featured_image {
								alt
								image
							}
						}
					}
				}
			}
			orderQuery: markdownRemark(frontmatter: { name: { eq: "Settings" } }) {
				frontmatter {
					listingsOrder {
						listingTitle
					}
				}
			}
		}
	`);
	*/

	//let listings = data.allMarkdownRemark.edges;
	//const orderingList = data.orderQuery.frontmatter.listingsOrder;

	//listings = collectionOrder(listings, orderingList);

	const url =
		`https://api.repliers.io/listings?pageNum=1&resultsPerPage=12&brokerage=Parker Coulter Realty Inc., Brokerage&sortBy=createdOnDesc`;
	const options = {
		method: 'POST',
		headers: {
			accept: 'application/json',
			'content-type': 'application/json',
			'REPLIERS-API-KEY': key,
		},
	};

	const [listings, setListings] = useState([]);
	const [searchParams, setSearchParams] = useState({
		search: '',
		class: '',
		minPrice: '',
		maxPrice: '',
		minBeds: '',
		minBaths: '',
	});

	const getSlug = (listing) => {
		return `${listing?.address?.streetNumber
			? listing.address.streetNumber.toLowerCase()
			: ''
			}-${listing?.address?.streetName ? listing.address.streetName.toLowerCase() : ''
			}-${listing?.address?.streetSuffix
				? listing.address.streetSuffix.toLowerCase()
				: ''
			}-${listing?.address?.city ? listing.address.city.toLowerCase() : ''}-${listing.mlsNumber
			}`.replace(' ', '-');
	};

	useEffect(() => {
		fetch(url, options)
			.then((res) => res.json())
			.then((json) => {
				console.log(json);
				setListings(json.listings);
			})
			.catch((err) => console.error('error:' + err));
	}, []);

	return (
		<StyledCarousel>
			{!!listings.length ? (
				<div className="columns is-centered">
					<div className="column is-8">
						<Carousel
							wrapAround={true}
							heightMode={'max'}
							renderCenterLeftControls={({ previousSlide }) => (
								<button
									className="control-button prev-button"
									onClick={() => previousSlide()}
									style={{ backgroundImage: `url(${prevIcon})` }}
								>
									<span className="visually-hidden">Previous Slide</span>
								</button>
							)}
							renderCenterRightControls={({ nextSlide }) => (
								<button
									className="control-button next-button"
									onClick={() => nextSlide()}
									style={{ backgroundImage: `url(${nextIcon})` }}
								>
									<span className="visually-hidden">Next Slide</span>
								</button>
							)}
							renderBottomCenterControls={() => null}
						>

							{listings &&
								listings.map((listing, i) => (
									<div className="column" key={getSlug(listing)}>
										<ListingDetailFeed listing={listing} />
									</div>
								))}

						</Carousel>
					</div>
				</div>
			) : (
				<div className="has-text-centered">
					<h3>
						We're Sorry. <br />
						There are no currently featured listings.
					</h3>
					<p>Please check back soon for updates.</p>
				</div>
			)}
		</StyledCarousel>
	);
};

export default FeaturedListingsCarousel;
