import React, { useState, useEffect, useRef } from 'react';
import Carousel from 'nuka-carousel';
import styled from 'styled-components';

const StyledCarousel = styled.div`
	.carousel-image {
		height: 90vh;
		background-size: cover;
		background-position: center;
		background-repeat: no-repeat;
	}

	.slider-control-bottomcenter {
		/* width: 15%; */
		right: 0 !important;
		left: 0 !important;
		bottom: 3rem !important;
		transform: none !important;
		z-index: 3;

		@media (max-width: 1087px) {
			padding: 0 1.9rem;
		}

		.indicator-bar {
			width: 30rem;
			height: 3px;
			background-color: rgba(255, 255, 255, 0.58);
			margin-left: auto;

			@media (max-width: 599px) {
				display: none;
			}

			.bar {
				width: calc(100% / ${(props) => props.imagesLength});
				height: 3px;
				background-color: ${(props) => props.theme.colors.lightest};
				transition: 0.4s;
				top: 0px;
				position: relative;

				&::before {
					content: '0${(props) => props.current}';
					font-family: ${(props) => props.theme.fonts.secondary};
					font-size: 1.8rem;
					letter-spacing: 2.6px;
					font-weight: ${(props) => props.theme.fonts.weights.regular};
					position: absolute;
					top: -3rem;
					color: ${(p) => p.theme.colors.lightest};
				}
			}
		}

		.contact-deets {
			display: flex;
			flex-direction: row;
			align-items: center;
			justify-content: flex-end;
			flex-wrap: wrap;
			margin-top: 3rem;

			li {
				font-size: 1.2rem;
				font-weight: ${(props) => props.theme.fonts.weights.bold};
				letter-spacing: 1.68px;
				white-space: nowrap;

			a {
					color: ${(props) => props.theme.colors.lightest};
					transition: 300ms;

					&:hover {
						color: ${(props) => props.theme.colors.primary};
						text-decoration: underline;
					}
				}

				+ li {
					margin-left: 2rem;
				}
			}
		}
	}
`;

const HomePageCarousel = ({ images }) => {
	const [slide, setSlide] = useState(0);
	const mounted = useRef(false);

	useEffect(() => {
		mounted.current = true;
		return () => {
			mounted.current = false;
		};
	}, []);

	return (
		<StyledCarousel imagesLength={images.length} current={slide + 1}>
			<Carousel
				// autoplay={true}
				wrapAround={true}
				autoplayInterval={7000}
				speed={2000}
				afterSlide={(prevSlide) =>
					mounted.current ? setSlide(prevSlide) : null
				}
				renderCenterLeftControls={() => null}
				renderCenterRightControls={() => null}
				renderBottomCenterControls={({ currentSlide }) => (
					<div className="container">
						{images.length > 1 && (
							<div className="indicator-bar">
								<div
									className="bar"
									style={{
										left: `calc((100% / ${images.length}) * ${currentSlide})`,
									}}
								/>
							</div>
						)}
						<ul className="contact-deets">
							<li>
								<a href="mailto:contact@parkercoulter.com">
									e: CONTACT@PARKERCOULTER.COM
								</a>
							</li>
							<li>
								<a href="tel:+17057229111">t: 705-722-9111</a>
							</li>
						</ul>
					</div>
				)}
			>
				{images.map((image, i) => (
					<div
						className="carousel-image"
						style={{
							backgroundImage: `url('${image.childImageSharp.fluid.src}')`,
						}}
						key={`${image.childImageSharp.fluid.src}-${i}`}
					/>
				))}
			</Carousel>
		</StyledCarousel>
	);
};

export default HomePageCarousel;
