import React, { useState, useEffect } from 'react';
import styled from 'styled-components';

import Modal from './Modal';
import NewsletterForm from '../forms/NewsletterForm';
import CloseIcon from './CloseIcon';

const StyledModalContent = styled.div`
	position: relative;
	.modal-content-upper {
		color: ${(p) => p.theme.colors.lightest};
		background-color: ${(p) => p.theme.colors.primary};
		padding: 70px 16px 50px;
		text-align: center;

		h2 {
			font-family: ${(p) => p.theme.fonts.secondary};
			font-size: 3rem;
			line-height: 1.5;
			text-transform: none;
			max-width: 17em;
			margin: 0 auto 0.5em;
			color: inherit;
		}

		p {
			font-size: 2rem;
			line-height: 1.5;
		}

		@media (max-width: 599px) {
			h2 {
				font-size: 2.3rem;
			}
		}
	}

	.modal-content-lower {
		padding: 2.5rem 1.6rem 7rem;
		.content-wrapper {
			max-width: 400px;
			margin: 0 auto;
		}
	}

	.close-modal {
		position: absolute;
		top: 1.7rem;
		right: 2rem;
		border: none;
		outline: none;
		background: rgba(255, 255, 255, 0);
		cursor: pointer;
		width: 3rem;
		height: 3rem;
		padding: 0;

		svg {
			width: 100%;
			height: auto;
		}
	}
`;

const style = {
	overlay: {
		zIndex: '100',
		padding: '5.5rem 2.7rem',
		backgroundColor: 'rgba(27,27,27, 0.8)',
		display: 'flex',
		alignItems: 'center',
		justifyContent: 'center',
	},
	content: {
		position: 'static',
		borderRadius: '0',
		border: '1px solid #707070',
		padding: '0',
		width: '100%',
		maxWidth: '980px',
		maxHeight: '100%',
	},
};

const NewsletterModal = () => {
	const [isModalOpen, setIsModalOpen] = useState(false);

	const toggleModal = () => {
		setIsModalOpen(!isModalOpen);
		sessionStorage.setItem('inhibitPcNewsletterModal', true);
	};

	useEffect(() => {
		const inhibitModal = sessionStorage.getItem('inhibitPcNewsletterModal');

		if (inhibitModal !== 'true') {
			const modalTimeout = setTimeout(function() {
				setIsModalOpen(true);
			}, 7000);

			return () => clearTimeout(modalTimeout);
		}
	}, []);

	return (
		<Modal isOpen={isModalOpen} style={style}>
			<StyledModalContent>
				<button className="close-modal" onClick={() => toggleModal()}>
					<span className="visually-hidden">Close This Popup</span>
					<CloseIcon />
				</button>
				<div className="modal-content-upper">
					<h2>Want to see new listings as soon as they hit the market?</h2>
					<p> Sign up to join our mailing list today!</p>
				</div>
				<div className="modal-content-lower">
					<div className="content-wrapper">
						<NewsletterForm />
					</div>
				</div>
			</StyledModalContent>
		</Modal>
	);
};

export default NewsletterModal;
