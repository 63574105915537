import styled from 'styled-components';

import mastheadFade from '../../img/masthead_fade.png';
import experienceBg from '../../img/index-page/home-experience-bg.jpg';

const StyledIndexPage = styled.article`
	h2 {
		+ [class^='AccentLine'] {
			margin-bottom: 1.5rem;

			+ .sub-head {
				font-family: ${(props) => props.theme.fonts.secondary};
				font-size: 2.5rem;
				letter-spacing: 3px;
			}
		}
	}

	.masthead-section {
		position: relative;
		height: 90vh;
		.masthead-fade {
			background-image: url(${mastheadFade});
			background-size: contain;
			background-repeat: no-repeat;
			background-position: top center;
			position: absolute;
			top: 0;
			right: 0;
			bottom: 0;
			left: 0;
			width: 100%;
		}

		.masthead-content {
			position: absolute;
			top: 0;
			right: 0;
			bottom: 0;
			left: 0;
			width: 1100px;
			max-width: 95%;
			margin: 0 auto;
			z-index: 2;
			display: flex;
			flex-direction: column;
			align-items: center;
			justify-content: center;
			color: ${(props) => props.theme.colors.lightest};

			@media (max-width: 768px) {
				padding-top: 25vh;
			}

			@media (min-width: 769px) {
				padding-top: 30vh;
			}

			h1 {
				margin-bottom: 2rem;
				font-weight: ${(p) => p.theme.fonts.weights.regular};

				@media (max-width: 768px) {
					font-size: 3rem;
					letter-spacing: 0.12em;
				}
			}

			.sub-head {
				font-size: 2rem;
				letter-spacing: 3px;
				line-height: 1.2;
				text-transform: uppercase;
				margin-top: 3rem;
				@media (max-width: 768px) {
					font-size: 4vw;
				}
			}

			.button {
				margin-top: 5rem;
			}
			.button-tray {
				padding-top: 5rem;
				display: flex;
				& > * {
					margin: 16px;
				}
			}
		}

		.arrow-down {
			position: absolute;
			left: 50%;
			bottom: -4rem;
			width: 40px;
			height: auto;
			transform: translateX(-50%);

			@media (max-width: 768px) {
				bottom: -6rem;
			}
		}
	}

	.save-section {
		background-color: ${(props) => props.theme.colors.secondary};
		color: ${(props) => props.theme.colors.lightest};
		padding-top: 10rem;
		padding-bottom: 5rem;

		h2 {
			font-size: 2rem;
			color: ${(props) => props.theme.colors.tertiary};
			+ [class^='AccentLine'] {
				+ .sub-head {
					font-size: 2.8rem;
				}
			}
		}

		[class^='SavingsSlider'] {
			margin-top: 12rem;
			margin-bottom: 2.5rem;
		}

		.button {
			margin-top: 3rem;
		}
	}

	.experience-section {
		background-image: url(${experienceBg});
		background-size: cover;
		background-position: center;
		background-repeat: no-repeat;

		.experience-vid-wrapper {
			max-width: 900px;
			margin: 2rem auto 0;
		}

		.experience-section-heading-wrapper {
			padding-top: 1rem;

			h2 {
				font-size: 1.8rem;
				line-height: 1;

				+ span {
					margin-top: 1.8rem;
				}
			}

			@media (min-width: 768px) {
				padding-top: 6rem;

				h2 {
					font-size: 2.2rem;
					font-size:
					line-height: 1.5;

					+ span {
						margin-top: 2.5rem;
					}
				}
			}
		}
	}

	.featured-section {
		padding-top: 10rem;
		padding-bottom: 5rem;

		@media (max-width: 768px) {
			padding-top: 5rem;
		}

		.sub-head {
			margin-bottom: 5rem;
		}

		[class*='FeaturedListingsCarousel'] {
			margin-bottom: 8rem;
		}

		.listings-link {
			font-size: 1.6rem;
			color: ${(props) => props.theme.colors.secondary};
			background: none;
			border: none;
			text-transform: uppercase;
			position: relative;
			cursor: pointer;

			&::before,
			&::after {
				transition: 500ms cubic-bezier(0.12, -1.05, 0.72, 0.9);
			}

			&::before {
				content: '';
				height: 0.1rem;
				width: 1.3rem;
				right: -2rem;
				position: absolute;
				top: 50%;
				transform: translateY(-50%);
				background-color: ${(props) => props.theme.colors.primary};
			}

			&::after {
				content: '';
				height: 0.75rem;
				width: 0.75rem;
				right: -1.9rem;
				position: absolute;
				top: 50%;
				transform: translateY(-50%) rotate(-45deg);
				border-right: 1px solid;
				border-bottom: 1px solid;
				border-right-color: ${(props) => props.theme.colors.primary};
				border-bottom-color: ${(props) => props.theme.colors.primary};
			}

			&:hover {
				&::before {
					transform: translateY(-50%) translateX(1rem);
				}

				&::after {
					transform: translateY(-50%) translateX(1rem) rotate(-45deg);
				}
			}
		}
	}

	.our-mission-section {
		padding-top: 10rem;
		padding-bottom: 10rem;
		background-size: cover;
		background-position: 70% top;
		background-repeat: no-repeat;

		@media (max-width: 768px) {
			background-image: none !important;
			padding-top: 5rem;
			padding-bottom: 0;
		}

		@media (min-width: 769px) and (max-width: 1279px) {
			padding-top: 5rem;
			padding-bottom: 5rem;
		}

		.mission-column {
			@media (max-width: 768px) {
				padding-bottom: 3rem;
			}

			.sub-head {
				font-weight: ${(p) => p.theme.fonts.weights.regular};
				margin-bottom: 1rem;
			}

			.services {
				margin-top: 4rem;

				h2 {
					margin-bottom: 1rem;
				}

				ul {
					li {
						font-size: 1.8rem;
						font-weight: ${(props) => props.theme.fonts.weights.regular};
						margin-bottom: 0.25em;
					}
				}
			}

			.button {
				margin-top: 5rem;
			}
		}

		.team-column {
			background-size: cover;
			background-repeat: no-repeat;
			background-position: center;
			height: 28vh;

			/* Accommodate for different bg image for mobile */
			@media (max-width: 768px) {
				height: 81vw;
				background-size: contain;
				margin: 0 -0.75rem;
			}

			@media (min-width: 769px) {
				background-image: none !important;
			}
		}
	}

	.testimonials-section {
		background-size: cover;
		background-position: center;
		background-repeat: no-repeat;
		padding-top: 10rem;
		padding-bottom: 10rem;

		[class^='AccentLine'] {
			background-color: ${(props) => props.theme.colors.textDarkerGrey};
		}

		.sub-head {
			/* margin-bottom: 3rem; */
		}
	}
`;

export default StyledIndexPage;
