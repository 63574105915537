const ytEmbedLink = (url) => {
	const youtubeEmbedBaseUrl = 'https://www.youtube.com/embed/';
	const parsedUrl = parseURL(url);

	const embedLink =
		parsedUrl.pathname === '/watch'
			? youtubeEmbedBaseUrl + parsedUrl.searchObject.v
			: url;

	return embedLink;
};

// Credit for this little beauty goes to:
// https://www.abeautifulsite.net/posts/parsing-urls-in-javascript/
const parseURL = (url) => {
	const parser = document.createElement('a');
	const searchObject = {};

	// Let the browser do the work
	parser.href = url;

	// Convert query string to object for convenience
	const queries = parser.search.replace(/^\?/, '').split('&');

	for (let i = 0; i < queries.length; i++) {
		const split = queries[i].split('=');
		searchObject[split[0]] = split[1];
	}

	return {
		protocol: parser.protocol,
		host: parser.host,
		hostname: parser.hostname,
		port: parser.port,
		pathname: parser.pathname,
		search: parser.search,
		searchObject: searchObject,
		hash: parser.hash,
	};
};

module.exports = {
	ytEmbedLink,
};
