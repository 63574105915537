import React, { useEffect, useState } from 'react';
import Img from 'gatsby-image';
import Modal from 'react-modal';
import styled from 'styled-components';

import { ytEmbedLink } from '../util/ytEmbedLink';

import theme from '../styles/theme/theme';

const StyledMasthead = styled.div`
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: flex-start;
	position: relative;
	min-height: 250px;
	background-color: ${theme.colors.secondary};
	padding: 30px 20px;
	${({ mobileBackground }) =>
		mobileBackground && mobileBackground.childImageSharp.fluid
			? `
            height: ${(1 / mobileBackground.childImageSharp.fluid.aspectRatio) *
							100}vw;
            `
			: ''}

	@media (min-width: 768px) {
		min-height: 460px;
		padding-bottom: 50px;

		${({ background }) =>
			background && background.childImageSharp.fluid
				? `
            height: ${(1 / background.childImageSharp.fluid.aspectRatio) *
							100}vw;
            `
				: ''}
	}

	.masthead-image {
		position: absolute !important;
		inset: 0px;
		box-sizing: border-box;
		padding: 0px;
		border: none;
		margin: auto;
		display: block;
		width: 0px;
		height: 0px;
		min-width: 100%;
		max-width: 100%;
		min-height: 100%;
		max-height: 100%;
		object-fit: cover;
		object-position: center center;

		&.mobile {
			@media (min-width: 768px) {
				display: none !important;
			}
		}

		&.desktop {
			display: none !important;

			@media (min-width: 768px) {
				display: block !important;
			}

			@media (min-width: 1536px) {
				display: none !important;
			}
		}

		&.wide {
			display: none !important;

			@media (min-width: 1536px) {
				display: block !important;
			}
		}
	}
`;

const PlayButton = styled.button`
	position: absolute;
	top: 50%;
	left: 50%;
	transform: translate(-50%, -50%);
	background-color: ${theme.colors.transparent};
	border: none;
	cursor: pointer;

	svg {
		width: calc(6vw + 20px);
		min-width: 40px;
		max-width: 100px;
		height: auto;

		path,
		g {
			transition: 300ms;
		}
	}

	&:hover {
		svg {
			#video-triangle {
				fill: ${theme.colors.primary};
			}

			#video-ring {
				stroke: ${theme.colors.primary};
			}
		}
	}
`;

const IframeWrapper = styled.div`
	position: relative;
	padding-top: 56.35%;

	iframe {
		position: absolute;
		top: 0;
		right: 0;
		bottom: 0;
		left: 0;
		height: 100%;
		width: 100%;
	}
`;

const ModalCloseButton = styled.button`
	position: absolute;
	top: 10px;
	right: 10px;
	background-color: ${theme.colors.transparent};
	border: none;
	cursor: pointer;

	svg {
		width: 30px;
		height: 30px;

		path {
			fill: ${theme.colors.lightest};
		}
	}
`;

Modal.setAppElement('#___gatsby');

export const VideoMasthead = ({
	background,
	mobileBackground,
	wideBackground,
	video,
	children,
	...props
}) => {
	const [vidId, setVidId] = useState(null);
	const [playerId, setPlayerId] = useState('');
	const [modalOpen, setModalOpen] = useState(false);
	const [bannerImage, setBannerImage] = useState();

	let player;

	useEffect(() => {
		if (video) {
			const vidUrlArray = ytEmbedLink(video).split('/');
			const id = vidUrlArray[vidUrlArray.length - 1];
			const idString = `youtube-player-${id}`;

			// Check to see if the API script is already loaded
			if (!window.YT) {
				// If not, load the script asynchronously
				const tag = document.createElement('script');
				tag.src = 'https://www.youtube.com/iframe_api';

				const firstScriptTag = document.querySelector('script');
				firstScriptTag.parentNode.insertBefore(tag, firstScriptTag);
			}

			setVidId(id);
			setPlayerId(idString);
		}
	});

	useEffect(() => {
		if (window && window.innerWidth) {
			const windowWidth = window.innerWidth;

			if (windowWidth < 768) {
				setBannerImage(mobileBackground ?? background);
			} else if (windowWidth >= 768 && windowWidth < 1536) {
				setBannerImage(background);
			} else if (windowWidth >= 1536) {
				setBannerImage(wideBackground ?? background);
			}
		}
	}, []);

	const loadVideo = (id, idString) => {
		// the Player object is created uniquely based on the id in props
		player = new window.YT.Player(idString, {
			videoId: id,
			playerVars: {
				autoplay: 1,
				rel: 0,
			},
		});
	};

	const handlePlayButtonClick = async () => {
		await setModalOpen(true);
		loadVideo(vidId, playerId);
	};

	const handleCloseModal = () => {
		setModalOpen(false);
	};

	return (
		<StyledMasthead
			background={background}
			mobileBackground={mobileBackground}
			{...props}
		>
			{bannerImage ? (
				<Img
					fluid={bannerImage.childImageSharp.fluid}
					className="masthead-image"
				/>
			) : null}
			{video && (
				<>
					<PlayButton onClick={() => handlePlayButtonClick()}>
						<span className="visually-hidden">Play Video</span>
						<span dangerouslySetInnerHTML={{ __html: theme.icons.video }} />
					</PlayButton>

					<Modal
						isOpen={modalOpen}
						shouldCloseOnEsc={true}
						shouldCloseOnOverlayClick={true}
						onRequestClose={handleCloseModal}
						style={{
							overlay: {
								display: 'flex',
								flexDirection: 'column',
								alignItems: 'center',
								justifyContent: 'center',
								zIndex: '100',
								backgroundColor: 'rgba(26, 24, 24, 0.5)',
							},
							content: {
								position: 'static',
								inset: 'unset',
								width: '100%',
								background: theme.colors.transparent,
								padding: '50px 10px 10px',
								border: 'none',
							},
						}}
					>
						<ModalCloseButton onClick={() => handleCloseModal()}>
							<div dangerouslySetInnerHTML={{ __html: theme.icons.close }} />
						</ModalCloseButton>
						<IframeWrapper>
							<div id={playerId} />
						</IframeWrapper>
					</Modal>
				</>
			)}
			<div style={{ zIndex: 10 }}>{children}</div>
		</StyledMasthead>
	);
};
